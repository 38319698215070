/* Components: Category tree
--------------------------------------------------------------------------------------------------------------------- */
.categorytree {
  ul {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: calc(100% + 40px);
    margin: 30px -20px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      margin: 0 0 0 10px;
      padding: 8px 12px;
      border: 1px solid $dark-grey;
      border-radius: 3px;
      line-height: 14px;

      @include transition(all 0.325s ease-in-out);

      &:first-of-type {
        margin: 0 0 0 20px;
      }

      &:last-of-type {
        margin: 0 20px 0 10px;
      }

      a {
        color: $dark-grey;

        @include transition(all 0.325s ease-in-out);
      }

      ul {
        display: none;
      }

      &:hover {
        @include responsive-up(tablet-large) {
          border-color: $black;
          background-color: $black;

          @include transition(all 0.325s ease-in-out);

          a {
            color: $white;

            @include transition(all 0.325s ease-in-out);
          }
        }
      }
    }
  }
}
