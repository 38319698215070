/* Components: Cookies
--------------------------------------------------------------------------------------------------------------------- */
.idxrcookies-block-user-nav {
  @include responsive-down(tablet-extra-small) {
    position: fixed;
    top: 0;
    left: 0;
  }

  &::before {
    height: 100vh !important;
    top: 0;
    left: 0;
  }

  #idxrcookies {
    #center {
      width: 100%;
      height: 100vh;
      top: 0 !important;
      left: 0 !important;

      @include responsive-down(tablet-extra-small) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .contenido {
        padding: 40px !important;
        background: $white;

        @include responsive-down(tablet-extra-small) {
          padding: 40px 20px !important;
        }

        .container {
          * {
            color: $black;
          }
        }
      }
    }

    p {
      text-align: left;

      a {
        text-decoration: underline;
        font-size: 0.9em;

        &:hover {
          @include responsive-up(tablet) {
            text-decoration: none;
          }
        }
      }
    }

    #buttons {
      a {
        border-radius: 3px !important;
        background: $black !important;
        color: $white !important;
        padding: 10px 30px !important;
        font-size: 1em;

        &#idxrcookiesOK {
          @include responsive-down(tablet-extra-small) {
            margin-bottom: 10px;
          }
        }

        &#cookiesConf {
          background: $white !important;
          color: $black !important;
          border: 1px solid $black;
          padding: 9px 30px !important;

          .cookies-conf {
            @include responsive-down(tablet-extra-small) {
              display: block !important;
            }
          }

          .cookies-icon {
            display: none !important;
          }
        }
      }
    }
  }
}

#cookieModal {
  @include responsive-down(tablet-extra-small) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  #cookieModalHeader {
    display: flex;
    align-items: center;
    background: $black;
    justify-content: center;
    border-radius: 0 !important;
    font-size: 14px;

    img {
      display: none !important;
    }
  }

  #cookieModalBody {
    @include responsive-down(tablet-extra-small) {
      flex-direction: column;

      ul {
        margin: 0;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        width: 100%;
        padding: 15px 30px;
        border-bottom: 1px solid $light-grey;
      }
    }
  }

  #cookieModalList {
    width: 300px;

    @include responsive-down(tablet-extra-small) {
      width: 100%;
    }

    .dlxctab-row {
      border-bottom: 1px solid #e8e8e8 !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      color: $light-grey;
      padding: 15px;

      @include responsive-down(tablet-extra-small) {
        padding: 0;
        margin-right: 20px;
        border-bottom: none !important;
      }

      &.active {
        color: $black;
      }
    }
  }

  #cookieModalContent {
    .dlxctab-content {
      padding: 10px 15px 0 30px;

      p {
        color: $light-grey;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
  }

  #cookieModalFooter {
    @include responsive-down(tablet-extra-small) {
      padding: 15px 0;
    }

    .cookie-info-page {
      color: $dark-grey;
      text-decoration: none;
    }

    .btn-config {
      background: $black;
      padding: 10px 30px;
      border-radius: 3px;

      &:hover {
        @include responsive-up(tablet-extra-small) {
          background: $light-grey;
        }
      }
    }
  }
}

.fancybox-item.fancybox-close {
  display: none !important;
}

.loading {
  animation: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -ms-animation: none !important;
  -o-animation: none !important;
}
