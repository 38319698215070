$icomoon: 'icomoon' !important;

@font-face {
  font-family: 'icomoon';
  src: url('/themes/pabloochoashoes/assets/css/fonts/icomoon.eot?7x1743');
  src:
    url('/themes/pabloochoashoes/assets/css/fonts/icomoon.eot?7x1743#iefix') format('embedded-opentype'),
    url('/themes/pabloochoashoes/assets/css/fonts/icomoon.ttf?7x1743') format('truetype'),
    url('/themes/pabloochoashoes/assets/css/fonts/icomoon.woff?7x1743') format('woff'),
    url('/themes/pabloochoashoes/assets/css/fonts/icomoon.svg?7x1743#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left::before {
  content: "\e900";
}

.icon-arrow-right-normal::before {
  content: "\e901";
}

.icon-arrow-right::before {
  content: "\e902";
}

.icon-bag::before {
  content: "\e903";
}

.icon-block::before {
  content: "\e904";
}

.icon-box::before {
  content: "\e905";
}

.icon-check::before {
  content: "\e906";
}

.icon-checkout::before {
  content: "\e907";
}

.icon-close::before {
  content: "\e908";
}

.icon-full-screen::before {
  content: "\e909";
}

.icon-grid-1::before {
  content: "\e90a";
}

.icon-grid-2::before {
  content: "\e90b";
}

.icon-grid-3::before {
  content: "\e90c";
}

.icon-heart::before {
  content: "\e90d";
}

.icon-loupe::before {
  content: "\e90e";
}

.icon-mail::before {
  content: "\e90f";
}

.icon-phone::before {
  content: "\e910";
}

.icon-pin::before {
  content: "\e911";
}

.icon-star::before {
  content: "\e912";
}

.icon-store::before {
  content: "\e913";
}

.icon-truck::before {
  content: "\e914";
}

.icon-user::before {
  content: "\e915";
}

.icon-zoom::before {
  content: "\e916";
}

.icon-zz-check-circle::before {
  content: "\e917";
}
