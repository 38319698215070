/* Pages: Brands critical
--------------------------------------------------------------------------------------------------------------------- */
.page-manufacturer {
  main {
    min-height: calc(100vh - 80px);
  }
}

.popular-brands {
  padding: 40px 0;

  @include responsive-down(mobile) {
    padding: 20px 0;
  }

  &__title {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  &__gallery {
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;

    @include responsive-down(tablet) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      min-width: calc(100% + 40px);
      margin: 0 -20px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__image {
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;

    img {
      transform: scale(1);

      @include transition(all 0.5s ease-in-out);
    }

    .btn {
      width: calc(100% - 60px);
      position: absolute;
      bottom: 30px;
      left: 30px;
      font-size: 12px;
      padding: 6px 12px;
      letter-spacing: 0.5px;
      text-transform: inherit;

      @include transition(all 0.5s ease-in-out);

      @include responsive-up(tablet) {
        visibility: hidden;
        opacity: 0;
        transform: translateY(150px);
      }
    }
  }

  &__gallery-item {
    width: calc(100% / 3 - 10px);
    margin-right: 15px;

    @include responsive-down(tablet) {
      display: inline-flex;
      flex-direction: column;
      width: auto;
      margin-right: 15px;
      min-width: calc(100% / 2.4);

      &:first-child {
        @include responsive-down(tablet) {
          margin-left: 20px;
        }
      }

      &:last-child {
        margin-right: 0;

        @include responsive-down(tablet) {
          margin-right: 20px;
        }
      }
    }

    @include responsive-down(mobile) {
      min-width: calc(100% / 1.6);
    }

    a {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      font-size: 16px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.05);

        @include transition(all 0.5s ease-in-out);
      }

      span {
        font-size: 16px;
        line-height: 26px;
      }

      &:hover {
        @include responsive-up(tablet) {
          color: $black;

          .popular-brands__image {
            img {
              transform: scale(1);

              @include transition(all 0.5s ease-in-out);
            }

            .btn {
              visibility: visible;
              opacity: 1;
              transform: translateY(0);

              @include transition(all 0.5s ease-in-out);
            }
          }
        }
      }
    }
  }
}
