/* Partials: Responsive: Only
--------------------------------------------------------------------------------------------------------------------- */

@mixin responsive-only($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (min-width: $tablet-extra-large) {
      @content;
    }
  }

  @else if $breakpoint == tablet {
    @media (min-width: $tablet-extra-small) and (max-width: $tablet-extra-large) {
      @content;
    }
  }

  @else if $breakpoint == mobile {
    @media only screen and (max-width: $tablet-extra-small) {
      @content;
    }
  }

  @else {
    // nothing
  }
}
