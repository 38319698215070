/* Partials: Header
--------------------------------------------------------------------------------------------------------------------- */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  z-index: 9;

  &__slider {
    display: flex;
    align-items: center;
  }

  &__slide {
    opacity: 1;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.07px;

    @include transition(all 0.25s ease-in);

    * {
      font-size: 11px;
      line-height: 15px;
      font-weight: 300;
      text-align: center;
    }
  }

  &__topbar {
    background-color: $black;
    color: $white;
    font-size: 11px;
    padding: 5px 0;
    height: 25px;
    opacity: 1;
    overflow: visible;
    transform: translateY(0);

    @include transition(transform 0.25s ease-in-out);

    @include responsive-up(tablet) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 50px;
      width: 100%;
    }

    &--hidden {
      .header__slide {
        opacity: 0;
      }
    }
  }

  &__trusted {
    display: flex;
    align-items: center;
    min-width: 150px;

    @include responsive-down(tablet) {
      text-align: center;
    }

    svg {
      width: 12px;
      fill: $white;
      margin-right: 4px;
    }

    p {
      display: inline-block;
      text-transform: uppercase;
      margin-right: 6px;
      font-size: 10px;

      span {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &__phone {
    text-align: center;

    @include responsive-up(tablet) {
      text-align: right;
      min-width: 76px;
    }

    i {
      display: inline-block;
      margin-right: 4px;
      margin-top: 2px;
    }

    a {
      color: $white;
      font-size: 11px;
    }
  }

  &__navigation {
    display: flex;
    align-content: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    padding-top: 15px;
    padding-bottom: 15px;

    @include transition(all 0.25s ease-in-out);

    @include responsive-up(tablet) {
      padding-top: 0;
      padding-bottom: 0;
    }

    // @include responsive-down(tablet) {
    //   padding-bottom: 0;
    // }

    body.page-category & {
      @include responsive-down(tablet) {
        padding-bottom: 55px;
      }
    }

    &--extra-padding {
      padding-bottom: 15px;

      @include responsive-down(tablet) {
        padding-bottom: 55px;
      }
    }
  }

  &__corporate {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100px;
  }

  &__search-form {
    width: 20px;
    height: 20px;
    margin-right: 15px;

    i {
      font-size: 20px;
      cursor: pointer;

      @include transition(all 0.5s ease-in-out);

      @include responsive-down(mobile-large) {
        opacity: 0;
        visibility: hidden;

        @include transition(opacity 0.5s ease-in-out);
      }
    }

    .input-search {
      position: absolute;
      right: 85px;
      top: calc(50% - 17px);
      height: 34px;
      background-color: $product-background-grey;
      border-radius: 3px;
      border: 1px solid #dbdbdb;
      padding: 5px 10px;
      font-size: 10px;
      z-index: 11;
      width: calc(100% - 105px);

      @include responsive-down(tablet) {
        visibility: hidden;
        opacity: 0;
      }

      @include transition(opacity 0.5s ease-in-out);

      @include responsive-up(tablet) {
        right: 216px;

        @include transition(all 0.5s ease-in-out);
      }

      @include responsive-up(mobile-large) {
        max-width: 300px;
      }

      &::placeholder {
        color: #c1c1c1;
      }
    }

    @include responsive-up(tablet) {
      margin-right: 30px;

      i {
        z-index: 13;
        position: relative;
        font-size: 18px;
      }

      .input-search {
        visibility: visible;
        opacity: 1;
        width: calc(100% - 105px);
      }
    }
  }

  &__user-area {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    min-width: 30px;
    padding: 2px;
    margin-right: 12.5px;
    background-color: transparent;
    border-radius: 100px;

    @include transition(all 0.25s ease-in-out);

    @include responsive-up(tablet) {
      margin-right: 30px;
    }

    i {
      font-size: 20px;
    }

    &:hover {
      @include responsive-up(tablet) {
        background-color: $product-background-grey;

        @include transition(all 0.25s ease-in-out);

        i {
          color: $black;
        }
      }
    }
  }

  &__wishlist {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    min-width: 30px;
    padding: 2px;
    margin-right: 12.5px;
    background-color: transparent;
    border-radius: 100px;

    @include transition(all 0.25s ease-in-out);

    @include responsive-up(tablet) {
      margin-right: 12.5px;
    }

    i {
      font-size: 20px;
    }

    &:hover {
      @include responsive-up(tablet) {
        background-color: $product-background-grey;

        @include transition(all 0.25s ease-in-out);

        i {
          color: $black;
        }
      }
    }
  }

  &__cart {
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;

    @include responsive-up(tablet) {
      width: 40px;
      height: 55px;
      padding: 17.5px 0 17.5px 17.5px;
    }

    .cart-products-count {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background: $black;
      color: $white;
      border-radius: 100%;
      width: 11px;
      height: 11px;
      font-size: 8px;
      line-height: 8px;
      bottom: -3px;
      right: -2px;

      @include responsive-up(tablet) {
        bottom: 14px;
      }
    }

    i {
      font-size: 20px;
    }
  }

  &__burger {
    width: 20px;
    height: 14px;
    position: relative;
    z-index: 11;

    @include transition (all 0.5s ease-in-out);

    @include responsive-up(tablet) {
      display: none;
    }

    .line {
      background: $black;
      height: 2px;
      width: 100%;
      position: absolute;
      left: 0;
      border-radius: 100px;
      transform-origin: 50% 50%;
      -webkit-transform-origin: 50% 50%;

      &--top {
        top: 0;
      }

      &--center {
        top: 50%;
      }

      &--bottom {
        top: 100%;
        width: 75%;
      }
    }
  }

  &__nav {
    margin: 0 40px 0 0;
    height: 100%;

    @include responsive-down (tablet) {
      width: 0;
      background: $white;
      top: 0;
      left: 0;
      margin: 0;
      z-index: 10;
      position: fixed;
      visibility: hidden;
      opacity: 0;
      height: 100vh;
      transform: translateX(-500px);
      overflow-y: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include transition(all 0.5s ease-in-out);
    }
  }

  &__menu {
    width: 20px;
    margin-right: 12px;
    height: 100%;
    display: flex;
    align-items: center;

    @include responsive-up(tablet) {
      width: auto;
      margin-right: initial;
      order: 2;
    }
  }

  &__logo {
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    z-index: 11;

    @include responsive-up(tablet) {
      order: 1;
    }

    span {
      font-weight: 700;
    }

    svg {
      width: 160px;
    }
  }

  &--opened {
    .header__menu {
      @include responsive-down(tablet) {
        width: 0;
        margin-right: 0;
      }
    }

    .header__nav {
      @include responsive-down(tablet) {
        visibility: visible;
        opacity: 1;
        width: 100%;
        transform: translateX(0);

        @include transition(all 0.5s ease-in-out);
      }
    }

    .header__burger {
      position: absolute;
      top: 20px;
      right: 20px;
      animation: transformBurger 3s;

      @keyframes transformBurger {
        from {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @include transition (all 0.5s ease-in-out);

      .line {
        &--top {
          transform: rotate(45deg);
          top: 50%;
        }

        &--center {
          transform: rotate(135deg);
        }

        &--bottom {
          display: none;
        }
      }
    }

    .header__topbar {
      @include responsive-down(tablet) {
        padding: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        transform: translateY(-25px);

        @include transition(all 0.25s ease-in-out);
      }

      .tns-outer {
        @include responsive-down(tablet) {
          display: none;
        }
      }
    }

    .header__search-form {
      display: none;
    }
  }

  &--fixed {
    .header__topbar {
      padding: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform: translateY(-25px);

      @include transition(all 0.45s ease-in-out);

      .tns-outer {
        display: none;
      }
    }

    .header-wrap__nav .nav-menu__item > ul {
      top: 55px;
    }
  }

  .nav-top {
    position: relative;
    top: 55px;

    @include responsive-up(tablet) {
      display: none;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
      position: relative;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% / 3);
        height: 66px;

        a {
          color: $white;
          z-index: 2;
        }

        img {
          display: none;
        }

        &.active {
          a {
            text-decoration: underline;
          }

          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  &-wrap__nav {
    height: 100%;

    @include responsive-down(tablet) {
      display: flex;
      width: 100%;
      align-items: flex-start;
      position: relative;
      top: 55px;
      padding: 0;
    }

    .nav-menu {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @include responsive-down(tablet) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        height: calc(100vh - 61px);
      }

      &::-webkit-scrollbar {
        display: none;
      }

      @include responsive-up(tablet) {
        display: flex;
        align-items: center;
        width: auto;
      }

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:first-of-type {
          padding-left: 40px;

          @include responsive-down(tablet) {
            padding-left: 0;
          }
        }

        @include responsive-up(tablet) {
          padding: 15px;
        }

        @include responsive-up(desktop-large-extra) {
          padding: 15px 30px;
        }

        &::before {
          @include responsive-down(tablet) {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: rgba(0, 0, 0, 0.25);
            width: 100%;
            height: 50px;
          }
        }

        &:first-child {
          @include responsive-down(tablet) {
            align-items: center;
            justify-content: center;
            width: calc(100% / 3);
            height: 50px;
            line-height: 16px;

            &::before {
              display: none;
            }
          }
        }

        &:nth-child(n+2) {
          @include responsive-down(tablet) {
            align-items: center;
            justify-content: center;
            width: calc(100% / 3);
            height: 50px;
            line-height: 16px;
          }
        }

        &:nth-child(n+4) {
          @include responsive-down(tablet) {
            display: none;
          }
        }

        a {
          font-size: 16px;

          @include responsive-down(tablet) {
            font-weight: 300;
            line-height: 13px;
            margin: 10px 0;
            padding: 0 20px;
            text-align: center;
            color: $white;
            z-index: 1;
          }

          @include responsive-down(mobile-extra-small) {
            margin: 0;
          }

          &.nav-brands__link-brands {
            color: $black;
            padding: 0;
            margin: 10px 0 30px;
            font-size: 20px;

            @include responsive-up(tablet) {
              display: none;
            }
          }
        }

        img {
          height: 50px;
          width: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;

          @include responsive-up(tablet) {
            position: absolute;
            left: inherit;
            right: 50px;
            top: 80px;
            width: 400px;
            height: 0;
            min-height: 0;
            padding: 0 0 50px;
            z-index: inherit;
            opacity: 0;
            visibility: hidden;

            @include transition(all 0.325s ease-in-out);
          }

          @include responsive-up(desktop) {
            right: 200px;
          }

          @include responsive-down(tablet) {
            display: none;
          }
        }

        & > ul {
          display: none;
          z-index: 1000;

          @include responsive-up(tablet) {
            border-top: 1px solid #e0e0e0;
          }

          @include responsive-down(tablet) {
            display: none;
            position: absolute;
            top: 55px;
            left: 0;
            width: 100%;
            height: auto;
            z-index: 9;
            padding: 0 20px;

            & > li {
              @include responsive-down(tablet) {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin: 30px 0;

                & > span {
                  a {
                    span {
                      display: inline-block;
                      margin: 10px 0;
                      font-size: 15px;
                      line-height: 20px;
                    }
                  }
                }

                a {
                  color: $black;
                  font-size: 22px;
                  line-height: 22px;
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  text-align: left;

                  @include responsive-down(tablet) {
                    font-size: 20px;
                    line-height: 18px;
                  }

                  .icon-arrow-left {
                    font-size: 13px;
                    margin-right: 5px;
                    display: inline-block;
                  }
                }

                &.nav-menu__item {
                  height: auto;
                  width: 100%;
                  margin: 30px 0;
                  justify-content: flex-start;
                  align-content: flex-start;

                  @include responsive-down(tablet) {
                    margin: 20px 0;
                  }

                  a {
                    color: $black;
                  }
                }

                &.submenu {
                  & > ul {
                    display: none;
                    background: $white;
                    height: calc(100vh - 61px);
                    position: fixed;
                    width: 100%;
                    left: 0;
                    top: 104px;
                    padding: 30px 20px;
                    z-index: 9;

                    .nav-menu__item {
                      height: auto;
                      line-height: inherit;
                      width: 100%;
                      justify-content: flex-start;
                    }

                    li {
                      margin: 20px 0;

                      &:first-child {
                        margin-top: 0;
                      }

                      a {
                        font-size: 14px;
                        color: $dark-grey;
                        position: relative;

                        &::after {
                          content: "\e901";
                          font-family: $icomoon;
                          position: relative;
                          font-size: 11px;
                          top: 1px;
                          left: 4px;
                        }
                      }

                      &.back {
                        a {
                          color: $black;

                          &::after {
                            content: none;
                          }
                        }
                      }

                      &.nav-menu__item {
                        a {
                          color: $black;
                          font-size: 20px;
                        }
                      }
                    }
                  }

                  &.submenu--active {
                    & > ul {
                      display: block;
                    }
                  }
                }
              }
            }
          }

          & > li {
            @include responsive-up(tablet) {
              width: 185px;

              & > a {
                font-size: 15px;
                line-height: 20px;
                margin: 10px 0;

                @include responsive-up(desktop-large-extra) {
                  font-size: 18px;
                  line-height: 24px;
                }
              }

              &.nav-menu__item {
                display: none;
              }
            }

            @include responsive-up(desktop) {
              width: 235px;

              & > a {
                max-width: 200px;
              }
            }
          }

          @include responsive-up(tablet) {
            display: flex;
            flex-direction: column;
            visibility: hidden;
            opacity: 0;
            height: 0;
            width: 100%;
            position: absolute;
            top: 55px;
            left: 0;
            padding: 25px 50px 50px;
            background-color: $white;
            transform: translateY(50px);

            ul {
              display: none;
              flex-direction: column;
              width: calc(100vw - 235px);
              position: absolute;
              left: 235px;
              background-color: $white;
              height: 100%;
              top: 0;
              padding: 25px 50px 50px 25px;

              @include responsive-up(desktop) {
                width: calc(100vw - 335px);
                left: 335px;
              }

              li {
                line-height: 26px;

                a {
                  font-size: 12px;
                  color: $dark-grey;

                  &:hover {
                    @include responsive-up(tablet) {
                      color: $light-grey;
                    }
                  }
                }

                &.back,
                &.nav-menu__item {
                  display: none;
                }
              }

              &::before {
                content: "";
                position: absolute;
                width: 1px;
                height: calc(100% - 85px);
                background: #e0e0e0;
                left: 0;
                top: 35px;
              }
            }

            li {
              &.submenu-opened {
                ul {
                  display: flex;
                }

                &::after {
                  content: "\e901";
                  font-family: $icomoon;
                  position: relative;
                  font-size: 11px;
                  top: 1px;
                  left: 4px;
                }
              }
            }
          }

          @include responsive-up(desktop) {
            padding: 25px 200px 50px;

            ul {
              width: calc(100vw - 435px);
              left: 435px;
            }
          }

          @include responsive-up(desktop-large-extra) {
            ul {
              li {
                line-height: 32px;

                a {
                  font-size: 14px;
                }
              }
            }
          }

          @include responsive-down(tablet) {
            &.submenu-opened {
              display: block;
              width: 100%;
              position: absolute;
              top: 0;
              background: $white;
              left: 0;
              padding: 20px;

              li {
                a {
                  font-size: 12px;
                  margin: 4px 0;
                  color: $dark-grey;
                }
              }

              img {
                display: none;
              }
            }
          }
        }

        &--opened {
          img {
            @include responsive-up(tablet) {
              height: 400px;
              min-height: 400px;
              opacity: 1;
              z-index: 1000;
              visibility: visible;

              @include transition(all 0.65s ease-in-out);
            }
          }

          & > ul {
            @include responsive-up(tablet) {
              visibility: visible;
              opacity: 1;
              height: auto;
              min-height: 525px;
              transform: translateY(0);

              @include transition(all 0.25s ease-in-out);
            }
          }

          .nav-brands {
            @include responsive-up(tablet) {
              visibility: visible;
              opacity: 1;
              height: auto;
              min-height: 425px;
              transform: translateY(0);

              @include transition(all 0.25s ease-in-out);
            }

            @include responsive-up(desktop) {
              padding: 25px 200px 50px;
            }
          }
        }

        .nav-menu__item {
          &::before {
            @include responsive-down(tablet) {
              display: none;
            }
          }
        }

        &.js-open-submenu--active {
          @include responsive-down(tablet) {
            & > ul {
              display: block;
              // max-height: 500px;
              height: calc(100vh - 61px);
              overflow: hidden;
              overflow-y: auto;
            }

            .nav-brands {
              display: block;
              left: 0;
              top: 50px;
              width: 100%;
              position: absolute;
              padding: 20px;
              opacity: 1;
              visibility: visible;
              height: auto;

              @include responsive-down(tablet) {
                top: 0;
              }

              &__featured {
                .nav-menu__item {
                  display: none;
                }

                li {
                  img {
                    position: inherit;
                    z-index: inherit;
                  }
                }

                ul {
                  li {
                    @include responsive-down(tablet) {
                      height: 70px;
                      background: $product-background-grey;
                      width: calc(50% - 5px);

                      &:nth-child(2n) {
                        margin-right: 10px;
                      }

                      &:nth-child(2n+1) {
                        margin-right: 0;
                      }
                    }

                    a {
                      @include responsive-down(tablet) {
                        width: 100%;
                        height: 100%;
                      }

                      img {
                        @include responsive-down(tablet) {
                          height: 60px;
                          object-fit: contain;
                        }
                      }
                    }
                  }
                }
              }

              &__featured-text {
                @include responsive-down(tablet) {
                  text-transform: uppercase;
                  color: #c6c6c6;
                  font-size: 9px;
                  line-height: 9px;
                }

                .brands-only-desktop {
                  @include responsive-down(tablet) {
                    display: none;
                  }
                }
              }

              &__top {
                .nav-menu__item {
                  display: none;
                }

                ul {
                  li {
                    a {
                      padding-left: 0;
                    }
                  }
                }
              }

              &__top-text {
                @include responsive-down(tablet) {
                  text-transform: uppercase;
                  color: #c6c6c6;
                  font-size: 9px;
                  line-height: 9px;
                  margin-top: 30px;
                  margin-bottom: 15px;
                }
              }

              &__img {
                display: block;
              }
            }

            & > .nav-menu__item-title {
              text-decoration: underline;
            }

            img {
              display: block;
            }
          }

          @include responsive-down(mobile-extra-small) {
            & > ul {
              max-height: 400px;
            }
          }
        }

        * {
          > &:empty {
            display: none;
          }
        }

        br {
          display: none;
        }

        .label-menu {
          display: inline-block;
          font-size: 9px;
          line-height: 10px;
          border-radius: 2px;
          background-color: #a60121;
          padding: 2px 3px;
          color: white;
          margin-left: 5px;
          position: relative;
          top: -1px;
        }
      }
    }
  }

  .nav-brands {
    display: flex;
    position: absolute;
    top: 55px;
    background: $white;
    width: 100%;
    left: 0;
    padding: 25px 50px;
    visibility: hidden;
    opacity: 0;
    height: 0;
    transform: translateY(50px);

    @include responsive-up(tablet) {
      z-index: 1000;
      border-top: 1px solid #e0e0e0;

      @include transition(all 0.25s ease-in-out);
    }

    & > p {
      text-transform: uppercase;
      color: $light-grey;
      font-size: 10px;
      margin-bottom: 10px;
      letter-spacing: 0.15px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 10px 0;

      li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: calc(50% - 5px);
        border-radius: 3px;
        overflow: hidden;

        @include responsive-down(tablet) {
          border-radius: 2px;
        }

        &:nth-child(2n+1) {
          margin-right: 10px;
          margin-bottom: 10px;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: $dark-grey;

          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            filter: grayscale(1);
            transform: scale(1);

            @include transition(all 0.325s ease-in-out);
          }

          &:hover {
            @include responsive-up(tablet) {
              img {
                filter: inherit;
                transform: scale(1.05);

                @include transition(all 0.325s ease-in-out);
              }
            }
          }
        }
      }
    }

    &__featured {
      @include responsive-up(tablet) {
        width: 220px;
        padding-right: 60px;

        .nav-menu__item {
          display: none;
        }

        p {
          font-size: 8px;
          text-transform: uppercase;
          color: $light-grey;
        }

        ul {
          flex-direction: column;

          li {
            justify-content: center;
            align-items: center;
            width: 160px;
            border-radius: 3px;
            overflow: hidden;
            height: 70px;
            padding: 0;
            background-color: $product-background-grey;
            margin-bottom: 10px;
            margin-right: 0;

            &.nav-menu__item {
              display: none;
            }

            a {
              width: 160px;
              height: 73px;
              padding: 5px;

              img {
                object-fit: contain;
                object-position: center;
                position: inherit;
                right: inherit;
                top: inherit;
                min-height: inherit;
                padding: 0;
                display: flex;
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &__top {
      @include responsive-up(tablet) {
        p {
          font-size: 8px;
          text-transform: uppercase;
          color: $light-grey;
        }

        .nav-menu__item {
          display: none;
        }

        ul {
          flex-direction: column;

          li {
            width: auto;
            margin-bottom: 10px;
            margin-right: 0;

            a {
              padding-right: 20px;

              &::after {
                content: "\e901";
                font-family: $icomoon;
                position: relative;
                font-size: 11px;
                top: 1px;
                left: 5px;
                opacity: 0;

                @include transition(all 0.25s ease-in-out);
              }

              &:hover {
                &::after {
                  opacity: 1;

                  @include transition(all 0.25s ease-in-out);
                }
              }
            }
          }
        }
      }
    }

    &__top-list {
      li {
        margin-bottom: 15px;
      }
    }

    &__img {
      @include responsive-down(tablet) {
        display: none;
      }

      img {
        top: 25px;

        @include responsive-down(tablet) {
          top: -50px;
        }
      }
    }
  }

  .nav-extra {
    margin-top: 45px;
    padding: 20px;

    @include responsive-down(tablet) {
      position: absolute;
      bottom: 15%;
      left: 0;
      width: 100%;
    }

    @include responsive-down(mobile-extra-small) {
      bottom: 5%;
    }

    @include responsive-up(tablet) {
      display: none;
    }

    ul {
      li {
        margin: 10px 0;

        &:first-child {
          display: none;
        }

        a {
          font-size: 15px;
          line-height: 25px;

          span {
            margin-left: 5px;
            font-size: 14px;
            color: $dark-grey;

            @include responsive-down(tablet) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  .language-selector {
    display: none;
  }

  &__hooks {
    display: none;
  }

  .search-category {
    &--scrolled {
      .header__search-form {
        i {
          position: absolute;
          z-index: 15;
          opacity: 1;
          visibility: visible;

          @include transition(opacity 0.5s ease-in-out);

          @include responsive-down(mobile-large) {
            top: 64px;
            right: 30px;
          }
        }

        .input-search {
          visibility: visible;
          opacity: 1;
          width: calc(100% - 40px);

          @include transition(opacity 0.5s ease-in-out);

          @include responsive-down(tablet) {
            visibility: visible;
            opacity: 1;
          }

          @include responsive-down(mobile-large) {
            top: 55px;
            right: 20px;
          }
        }
      }
    }
  }
}

.page-category .header {
  &__navigation--extra-padding {
    @include responsive-down(tablet) {
      padding-bottom: 59px;
    }
  }

  .search-category {
    .header__search-form {
      i {
        position: absolute;
        z-index: 15;
        opacity: 1;
        visibility: visible;

        @include transition(opacity 0.5s ease-in-out);

        @include responsive-down(mobile-large) {
          top: 64px;
          right: 30px;
        }
      }

      .input-search {
        visibility: visible;
        opacity: 1;
        width: calc(100% - 40px);

        @include transition(opacity 0.5s ease-in-out);

        @include responsive-down(tablet) {
          visibility: visible;
          opacity: 1;
        }

        @include responsive-down(mobile-large) {
          top: 55px;
          right: 20px;
        }
      }
    }
  }
}

.shadow-background {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100vh;
  z-index: -1;
  opacity: 0;

  @include transition (all 0.15s ease-in-out);

  &--active {
    opacity: 1;
    z-index: 9;

    @include transition (all 0.15s ease-in-out);
  }
}
