/* Components: Category Gridview
--------------------------------------------------------------------------------------------------------------------- */
.gridview {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  &__text {
    margin-bottom: 10px;
    margin-right: 10px;
    width: 100%;
  }

  &__item {
    display: inline-block;
    opacity: 0.25;
    cursor: pointer;

    @include transition(all 0.5s ease-in-out);

    &:first-of-type {
      margin-right: 10px;
    }

    &--active {
      opacity: 1;

      @include transition(all 0.5s ease-in-out);
    }

    &:nth-child(2) {
      @include responsive-down(tablet) {
        i {
          &::before {
            content: "\e90a";
          }
        }
      }

      @include responsive-up(tablet) {
        i {
          font-size: 15px;
        }
      }
    }

    &:nth-child(3) {
      @include responsive-down(tablet) {
        i {
          &::before {
            content: "\e90b";
          }
        }
      }

      @include responsive-up(tablet) {
        width: 30px;
        height: 30px;

        i {
          font-size: 30px;
        }
      }
    }
  }
}

.only-desktop {
  border-top: 1px solid #e0e0e0;
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include responsive-down(tablet) {
    display: none;
  }

  .gridview {
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 0;

    &__text {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  .js-filters-desktop {
    display: flex;
    align-items: center;
  }
}
