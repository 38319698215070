/* Common: Grid
--------------------------------------------------------------------------------------------------------------------- */

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include responsive-down(tablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include responsive-down(mobile-large) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &--large {
    max-width: 1920px;
  }

  &--overflow {
    @include responsive-down(tablet) {
      overflow: hidden;
    }
  }

  &--medium {
    max-width: 1600px;

    @include responsive-down(desktop-small) {
      max-width: 1350px;
    }
  }

  &--medium-small {
    max-width: 1200px;
  }

  &--small {
    max-width: 1024px;
  }

  &--extrasmall {
    max-width: 850px;
  }

  &--mobile-large-15 {
    @include responsive-down(mobile-large) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
