/* Common: Classes
--------------------------------------------------------------------------------------------------------------------- */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

.h2-custom {
  color: red;
}

body {
  font-family: $primary-tipography;
}

main {
  margin-top: 80px;

  @include responsive-down(tablet) {
    margin-top: 86px;
    margin-bottom: 40px;
  }
}

p {
  letter-spacing: 0.07px;
  font-weight: 300;

  @include responsive-down(tablet) {
    font-size: 16px;
    line-height: 20px;
  }
}

h1,
.h1 {
  font-weight: 500;
  font-size: 20px;
}

ul {
  list-style: none;

  li {
    font-size: 14px;
  }
}

a {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  color: $primary;
  text-decoration: none;

  @include transition(all 0.25s ease-in-out);

  @include responsive-down(tablet) {
    font-size: 16px;
  }

  &:hover {
    @include responsive-up(tablet-large) {
      color: $grey;

      @include transition(all 0.25s ease-in-out);
    }
  }
}

img {
  width: 100%;
  will-change: transform;
}

textarea {
  width: 100%;
  resize: vertical;
  background-color: $product-background-grey;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  padding: 22px 16px 10px;
  font-size: 12px;
  color: $primary;
  font-family: $primary-tipography;
}

.form-group {
  label {
    display: block;
    position: relative;
    max-height: 0;
    font-weight: 400;
    top: -8px;

    &::before {
      content: attr(data-content);
      font-size: 12px;
      font-weight: 400;
      color: $primary;
      display: inline-block;
      filter: blur(0);
      backface-visibility: hidden;
      transform-origin: left top;
      transition: transform 0.2s ease;
      left: 1rem;
      position: relative;
    }

    &:focus {
      top: 0;
    }

    &.required {
      &::before {
        content: attr(data-content) " *";
      }
    }
  }

  input {
    &[type=text],
    &[type=number],
    &[type=email],
    &[type=password],
    &[type=tel] {
      width: 100%;
      background-color: $product-background-grey;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      padding: 22px 16px 10px;
      font-size: 12px;
      color: $primary;

      &::placeholder {
        color: transparent;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &--checkbox {
    width: 100% !important;
    margin: 10px 0 !important;

    label {
      max-height: inherit;
      top: inherit;
    }

    .custom-control {
      display: flex;
      align-items: flex-start;

      .custom-control-label {
        font-size: 13px;
        line-height: 13px;
        color: $dark-grey;
        margin-left: 5px;

        em {
          max-width: 550px;
          width: 100%;
          display: block;
          font-size: 10px;
          margin-top: 5px;
          color: $light-grey;
        }
      }

      .radio {
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &--radio {
    .custom-control {
      order: 2;
    }

    .custom-control-label {
      font-size: 11px;
    }

    .label.mr-3 {
      color: $light-grey;
      font-size: 11px;
      margin-right: 20px;
    }
  }

  &--password {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .input-group__help-text {
      order: 3;
    }
  }

  .text-muted {
    display: none;
  }

  select {
    width: 100%;
    background-color: $product-background-grey;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    padding: 22px 16px 10px;
    font-size: 12px;
    color: $primary;
  }
}

input:focus + label::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

input:placeholder-shown + label::before {
  transform: translate3d(0, -30px, 0) scale3d(1, 1, 1);
}

label::before {
  transform: translate3d(0, -40px, 0) scale3d(0.82, 0.82, 1);
}

input:focus + label::before {
  color: $light-grey !important;
  transform: translate3d(0, -40px, 0) scale3d(0.82, 0.82, 1);
}

.invalid-feedback:empty {
  display: none;
}

::-moz-selection {
  color: $white;
  background: $black;
}

::selection {
  color: $white;
  background: $black;
}
