/* Components: Category nav
--------------------------------------------------------------------------------------------------------------------- */
.subcat-nav {
  @include responsive-down(tablet-large) {
    display: none;
  }

  & > ul {
    & > li {
      font-size: 14px;
      margin-top: 25px;
      cursor: pointer;

      i {
        font-size: 11px;
        margin-left: 8px;
        color: $dark-grey;
      }

      & > ul {
        & > li {
          a {
            font-size: 11px;
            line-height: 15px;
            margin: 6px 0;
            color: $light-grey;
          }

          &:first-child {
            a {
              margin-top: 10px;
            }
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
