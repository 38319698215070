/* components:: cursor-custom
--------------------------------------------------------------------------------------------------------------------- */
.cursorcustom {
  position: fixed;
  width: 0;
  top: 0;
  left: 0;
  z-index: 100000000000000;
  pointer-events: none;
  will-change: transform;
  display: none;
  visibility: hidden;

  @include responsive-down(tablet) {
    opacity: 0;
    visibility: hidden;
    display: none;
  }

  &__cursorsmall {
    position: absolute;
    z-index: 1000;
    transform-origin: 50% 50%;
    will-change: transform;
    background-color: #f7f8fa;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transition: all 0.5s ease-out;
  }

  &__cursorbig {
    position: absolute;
    z-index: 1000;
    transform-origin: 50% 50%;
    will-change: transform;
    background-color: $primary;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.5s ease-out;
  }

  &--medium {
    .cursorcustom__cursorsmall {
      width: 8px;
      height: 8px;
      animation: none;
      opacity: 0;
    }

    .cursorcustom__cursorbig {
      width: 44px;
      height: 44px;
      background-color: transparent;
      border: 1px solid $primary;
      animation: cursorMedium 1s ease;
    }

    @keyframes cursorMedium {
      from {
        width: 8px;
        height: 8px;
      }

      to {
        width: 44px;
        height: 44px;
      }
    }
  }

  @keyframes cursorNormal {
    100% {
      width: 24px;
      height: 24px;
    }
  }
}
