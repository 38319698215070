/* Pages: Category special
--------------------------------------------------------------------------------------------------------------------- */

.category-special {
  @include responsive-up(tablet) {
    display: flex;
    flex-direction: column;
  }

  &__search-form {
    position: relative;
    padding: 10px 0;

    @include responsive-up(tablet) {
      display: none;
    }

    .header__search-form {
      width: 100%;
      height: 30px;
      margin-right: 0;

      .input-search {
        visibility: visible;
        opacity: 1;
        width: calc(100% - 40px);
        right: 20px;
        z-index: 1;

        &::placeholder {
          font-weight: 300;
          letter-spacing: 0.07px;
        }
      }

      .icon-loupe {
        position: absolute;
        right: 25px;
        top: 17px;
        font-size: 15px;
        z-index: 2;
      }
    }
  }

  &__intro {
    display: flex;
    align-items: stretch;
    position: relative;
    perspective: 1000px;
    overflow: hidden;

    @include responsive-up(tablet) {
      order: 1;
    }

    .text {
      padding: 100px 100px 100px 20px;
      position: relative;
      z-index: 2;

      @include responsive-up(tablet) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 20px 100px 20px 120px;
        width: 500px;
        min-width: 500px;
      }

      @include responsive-up(desktop) {
        width: 600px;
        min-width: 600px;
      }

      @include responsive-up(desktop-large-extra) {
        width: 800px;
        min-width: 800px;
      }

      h1 {
        font-size: 35px;
        line-height: 40px;
        font-weight: 300;
        color: $white;
        animation-name: introFromLeft1;
        animation-duration: 1.25s;

        @include responsive-up(tablet) {
          color: $black;
          font-size: 45px;
          line-height: 50px;
        }

        @include responsive-up(desktop-large-extra) {
          font-size: 60px;
          line-height: 65px;
        }
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: $white;
        margin: 10px 0;
        animation-name: introFromLeft2;
        animation-duration: 1.5s;

        @include responsive-up(tablet) {
          color: $black;
          color: $dark-grey;
          width: 75%;
          font-size: 12px;
          line-height: 20px;
          margin: 15px 0;
        }

        @include responsive-up(desktop) {
          width: 55%;
        }

        @include responsive-up(desktop-large-extra) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      a {
        width: auto;
        background: $black;
        color: $white;
        padding: 10px 30px;
        border-radius: 3px;
        animation-name: introFromLeft3;
        animation-duration: 2s;
        text-transform: inherit;
        letter-spacing: 0;
        border: none;
        text-align: center;

        @include transition(all 0.25s ease-in-out);

        @include responsive-up(tablet) {
          color: $white;
        }

        @include responsive-up(desktop-large-extra) {
          min-width: 225px;
        }

        &:hover {
          @include responsive-up(tablet) {
            background: $dark-grey;

            @include transition(all 0.25s ease-in-out);
          }
        }
      }

      @keyframes introFromLeft1 {
        from {
          opacity: 0;
          transform: translateX(-500px);
        }

        to {
          opacity: 1;
          transform: translateX(0);
        }
      }

      @keyframes introFromLeft2 {
        from {
          opacity: 0;
          transform: translateX(-500px);
        }

        to {
          opacity: 1;
          transform: translateX(0);
        }
      }

      @keyframes introFromLeft3 {
        from {
          opacity: 0;
          transform: translateX(-500px);
        }

        to {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    .video-preload {
      background: $product-background-grey;
      min-height: 520px;
      width: 100%;
      min-width: 100%;
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;

      @include responsive-up(desktop) {
        min-height: 735px;
      }
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      @include responsive-up(tablet) {
        position: inherit;
        z-index: 1;
        display: flex;
        animation-name: perspectiveRotateImg;
        animation-duration: 2s;

        @keyframes perspectiveRotateImg {
          from {
            opacity: 0;
            transform: scale(0.5) rotateY(-15deg);
          }

          to {
            opacity: 1;
            transform: scale(1) rotateY(0);
          }
        }
      }

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 1;

      @include responsive-up(tablet) {
        display: none;
      }
    }

    &--full-landing {
      height: calc(100vh - 81px);

      .text {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.35), transparent);

        @include responsive-down(tablet) {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        }

        &__h1 {
          color: $white !important;
        }

        p {
          color: $white;
        }

        a {
          background: transparent;
          padding: 0;
          padding-bottom: 2px;
          position: relative;
          font-weight: 300;
          text-transform: uppercase;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: $white;

            @include transition(all 0.25s ease-in-out);
          }

          &:hover {
            @include responsive-up(tablet) {
              background: transparent;

              &::before {
                height: 0;

                @include transition(all 0.25s ease-in-out);
              }
            }
          }
        }
      }

      .video {
        position: absolute;
      }
    }
  }

  &__subcategories {
    padding: 20px 0;

    @include responsive-up(tablet) {
      padding: 60px 0 20px;
      order: 2;
    }

    .subcategories-list {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      margin-left: 0;
      padding-top: 20px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      @include responsive-up(tablet) {
        padding-top: 50px;
        animation-name: fromBottomToTop;
        animation-duration: 3s;

        @keyframes fromBottomToTop {
          from {
            opacity: 0;
            transform: translateY(300px);
          }

          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      @include responsive-up(desktop) {
        padding-top: 65px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &__item {
        display: inline-block;
        width: 90px;
        margin-right: 16px;
        position: relative;

        @include responsive-up(tablet) {
          margin-right: 65px;
        }

        @include responsive-up(desktop) {
          width: 120px;
          margin-right: 120px;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          transform: translateY(0);

          @include transition(all 0.25s ease-in-out);

          img {
            width: 80%;
          }

          p {
            font-size: 14px;
            color: $dark-grey;
            margin-top: 14px;
            white-space: break-spaces;
            text-align: center;

            @include responsive-up(desktop-large-extra) {
              font-size: 15px;
              margin-top: 24px;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 5px;
          left: calc(50% - 35px);
          width: 70px;
          height: 70px;
          background: #f6f6f6;
          border-radius: 100px;
          z-index: -1;
          transform: translateY(0);

          @include transition(all 0.25s ease-in-out);

          @include responsive-up(desktop) {
            top: 20px;
            left: calc(50% - 40px);
            width: 80px;
            height: 80px;
          }
        }

        &:first-child {
          margin-left: 20px;

          @include responsive-up(tablet) {
            margin-left: 120px;
          }
        }

        &:last-child {
          @include responsive-up(tablet) {
            margin-right: 50px;
          }
        }

        &:hover {
          @include responsive-up(tablet) {
            a,
            &::before {
              transform: translateY(-20px);

              @include transition(all 0.25s ease-in-out);
            }
          }
        }
      }
    }

    &--landing {
      padding: 100px 0;
      order: inherit;

      @include responsive-down(tablet) {
        padding: 50px 0;
      }

      .subcategories-list {
        &__item {
          p {
            font-size: 11px;
            color: $dark-grey;
            margin-top: 14px;
            text-align: center;
          }
        }
      }
    }
  }

  &__collection {
    margin: 40px 0 0;

    @include responsive-up(tablet) {
      order: 4;
    }

    .intro {
      font-size: 14px;
      line-height: 24px;
      margin: 10px 0;
      margin-bottom: 30px;
      color: $dark-grey;
      max-width: 50%;

      @include responsive-up(desktop-large-extra) {
        font-size: 16px;
        line-height: 24px;
      }

      @include responsive-down(tablet) {
        font-size: 14px;
        line-height: 24px;
        max-width: 100%;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 10px 0;
        color: $black;
        font-weight: 400;

        @include responsive-up(desktop-large-extra) {
          margin: 40px 0;
          font-size: 40px;
        }
      }
    }

    .product-list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 10px;

      @include responsive-up(tablet) {
        padding: 0 50px;
      }

      @include responsive-down(tablet) {
        display: block;
      }

      article {
        display: inline-block;
        width: calc(100% / 5 - 5px);
        margin-right: 5px;
        margin-bottom: 40px;

        @include responsive-down(tablet) {
          display: inline-flex;
          width: calc(100% / 2 - 10px);
          margin: 5px 0;
        }
      }

      .product {
        position: relative;

        &__slider {
          a {
            width: 100%;
            display: grid;
            overflow: hidden;

            .img-inside {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        &__gallery {
          img {
            transform: scale(1);

            @include transition (all 0.5s ease-in-out);
          }
        }

        &__favourites {
          width: auto;
          position: absolute;
          top: 10px;
          right: 10px;
          height: auto;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;

          a {
            line-height: 13px;

            i.js-productwishlist-added {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              background: $red-primary;
              color: $white;
              padding: 4px 3px 3px;
              border-radius: 50%;
              width: 20px;
              height: 20px;
            }
          }
        }

        &__description {
          .product__title {
            a {
              color: $dark-grey;
              font-size: 14px;
              margin-top: 10px;
              line-height: 18px;

              @include responsive-up(desktop-large-extra) {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 10px;
              }
            }
          }

          .product__price {
            @include responsive-up(desktop-large-extra) {
              font-size: 20px;
              line-height: 20px;
            }
          }
        }

        &__title {
          @include responsive-down(tablet) {
            white-space: initial;
          }
        }

        &__slider-nav {
          @include responsive-down(tablet) {
            display: none;
          }
        }

        &:hover {
          @include responsive-up(tablet) {
            .product__gallery {
              img {
                transform: scale(1.1);

                @include transition (all 0.5s ease-in-out);
              }
            }
          }
        }
      }

      .add-product-image-connectif {
        display: block;

        &:empty {
          display: none;
        }
      }

      &--flexwrap {
        flex-wrap: wrap;

        article {
          margin-bottom: 40px;
        }
      }
    }

    &--landing {
      order: inherit;
    }

    &--promotion {
      order: 2;

      .product-list {
        @include responsive-down(tablet) {
          display: flex;
        }
      }
    }
  }

  &__sales {
    width: calc(100% - 20px);
    margin: 40px 20px 40px 0;
    position: relative;

    @include responsive-up(tablet) {
      order: 6;
      width: calc(100% - 150px);
      min-height: 500px;
      margin: 40px 150px 40px 0;
    }

    @include responsive-up(desktop) {
      min-height: 650px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 1;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: left;
      }
    }

    .text {
      padding: 100px 100px 100px 20px;
      position: relative;
      z-index: 2;

      @include responsive-up(tablet) {
        padding: 50px 0 50px 150px;
        height: 100%;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 450px;
      }

      @include responsive-up(desktop) {
        max-width: 600px;
        min-height: 650px;
      }

      h2,
      h3 {
        font-size: 35px;
        line-height: 40px;
        font-weight: 300;
        color: $white;

        @include responsive-up(desktop) {
          font-size: 50px;
          line-height: 60px;
        }
      }

      p {
        font-size: 14px;
        line-height: 18px;
        color: $white;
        margin: 10px 0;

        @include responsive-up(tablet) {
          font-size: 12px;
          line-height: 21px;
          margin: 15px 0;
          max-width: 75%;
        }

        @include responsive-up(desktop) {
          width: 55%;
        }

        @include responsive-up(desktop-large-extra) {
          width: 75%;
          font-size: 16px;
          line-height: 24px;
        }
      }

      a {
        background: $white;
        padding: 10px 30px;
        border-radius: 3px;

        @include transition(all 0.25s ease-in-out);

        @include responsive-up(desktop-large-extra) {
          min-width: 225px;
          text-align: center;
        }

        &:hover {
          @include responsive-up(tablet) {
            background: $black;
            color: $white;

            @include transition(all 0.25s ease-in-out);
          }
        }
      }
    }
  }

  .category-list {
    @include responsive-up(tablet) {
      order: 9;
    }
  }

  .text-seo {
    @include responsive-up(tablet) {
      order: 10;
    }
  }

  .showlist {
    order: 2;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 50px 0;

      button {
        width: auto;
        letter-spacing: 0.5px;
        text-transform: none;
      }
    }

    &__list {
      display: none;
      padding: 0 50px;

      @include responsive-down(tablet) {
        padding: 0 20px;
      }

      .products__list {
        display: flex;
        flex-wrap: wrap;
        gap: 0;

        article {
          display: inline-block;
          width: calc(100% / 5 - 5px);
          margin-right: 5px;
          margin-bottom: 40px;

          @include responsive-down(tablet) {
            width: calc(100% / 2 - 20px);
            margin: 10px;
          }
        }
      }
    }

    &--active {
      .showlist__button {
        display: none;
      }

      .showlist__list {
        display: block;
      }
    }
  }
}

.category-coleccion {
  .category-special {
    &__intro {
      order: 1;
    }

    &__collection {
      order: 2;
    }

    &__subcategories {
      order: 3;
    }
  }
}

.category-mujer,
.category-hombre {
  .product-list {
    @include responsive-down(tablet) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    article {
      @include responsive-down(tablet) {
        width: calc(100% / 2.2 - 10px);
        margin: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
