/* Breakpoints
/-------------------------------------------------------------------------------------------------------------------- */
$desktop-extra-large: 2560px;
$desktop-large-extra: 2000px;
$desktop-large: 1919px;
$desktop: 1600px;
$desktop-small: 1440px;
$desktop-extra-small: 1366px;
$tablet-extra-large: 1280px;
$tablet-large: 1080px;
$tablet: 991px;
$tablet-small: 860px;
$tablet-extra-small: 768px;
$mobile-extra-large: 720px;
$mobile-large: 640px;
$mobile: 540px;
$mobile-small: 480px;
$mobile-extra-small: 375px;

/* Typography
--------------------------------------------------------------------------------------------------------------------- */
$font-aeonik: 'aeonik', sans-serif;
$icomoon: 'icomoon', sans-serif;
$letter-spacing: 1;
$primary-tipography: 'Soleil', sans-serif;

/* Color
/-------------------------------------------------------------------------------------------------------------------- */
$primary: #000;
$black: #000;
$light-black: #252525;
$grey: #777;
$light-grey: #989898;
$product-background-grey: #f6f6f6;
$dark-grey: #5a5a5a;
$white: #fff;
$light-light-grey: #f6f6f6;
$red-primary: #a60121;
$red-primary-brackground: rgba(166, 1, 37, 0.2);
$warning: #d32f2f;
$success: #77d189;
$gold: #efc103;

//Animations
$easing: cubic-bezier(0.25, 1.7, 0.35, 0.8);
$duration: 0.75s;

//Box-shadow
$box-shadow-generic: 0 0 20px 5px rgba(34, 34, 34, 0.05);
