/* Pages: Subcategory
--------------------------------------------------------------------------------------------------------------------- */
.container-subcat {
  @include responsive-up(tablet-large) {
    display: flex;
  }

  @include responsive-up($desktop-large-extra) {
    padding-top: 40px;
  }

  .subcat-nav {
    @include responsive-up(tablet-large) {
      width: 250px;
      padding: 20px 20px 0 0;
    }
  }

  .subcategory {
    @include responsive-up(tablet-large) {
      width: calc(100% - 250px);
    }

    &__navigation {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .breadcrumb {
        background-color: $white;
        margin-bottom: 0;
        padding: 20px 0;
        display: flex;

        &-item {
          @include responsive-up(tablet) {
            font-size: 11px;
            line-height: 11px;
            margin-left: 7px;
            padding-left: 7px;
            color: $dark-grey;
            border-left: 1px solid $light-grey;
          }

          @include responsive-up(desktop-large-extra) {
            font-size: 13px;
            line-height: 13px;
          }

          &:first-child {
            @include responsive-up(tablet) {
              margin-left: 0;
              padding-left: 0;
              border-left: none;
            }
          }

          i {
            @include responsive-up(tablet) {
              display: none;
            }
          }

          &:not(:last-child),
          &__text {
            display: none;

            @include responsive-up(tablet) {
              display: inline-block;
            }
          }
        }
      }
    }

    &__content {
      max-width: 1000px;
      width: 100%;
      font-size: 13px;
      line-height: 18px;
      margin: 10px 0 30px;
      color: $dark-grey;

      @include responsive-up(tablet-large) {
        display: flex;
        align-items: flex-start;
        margin: 25px 0;
      }

      @include responsive-up($desktop-large-extra) {
        margin: 25px 0 50px;
      }

      h1 {
        color: $black;
        margin-bottom: 15px;
        line-height: 24px;

        @include responsive-up(tablet-large) {
          width: 200px;
          min-width: 200px;
          margin-right: 50px;
        }
      }
    }

    & > ul {
      display: flex;
      width: 100%;
      margin: 20px 0;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      @include responsive-down(tablet) {
        width: calc(100% + 40px);
        margin: 40px -20px;

        li {
          &:first-child {
            margin-left: 20px;
          }
        }
      }

      a {
        display: inline-block;
        border: 1px solid $dark-grey;
        color: $dark-grey;
        padding: 5px 15px;
        border-radius: 3px;
        margin-right: 15px;
        font-size: 16px;

        @include transition (all 0.5s ease-in-out);

        @include responsive-up($desktop-large-extra) {
          margin-right: 30px;
          font-size: 14px;
        }

        &:hover {
          @include responsive-up(tablet) {
            color: $white;
            background-color: $black;
            border-color: $black;

            @include transition (all 0.5s ease-in-out);
          }
        }
      }
    }
  }
}
