/* Layouts: Pages: Home
--------------------------------------------------------------------------------------------------------------------- */
.page-index {
  main {
    margin-top: 86px;

    @include responsive-up(tablet) {
      margin-top: 81px;
    }

    .homepage {
      &__intro {
        height: calc(100vh - 86px);
        position: relative;

        @include responsive-up(tablet) {
          height: calc(100vh - 81px);
        }

        @include responsive-up(desktop-large-extra) {
          max-height: 80vh;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.25);
          z-index: 2;
        }
      }

      &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 1;

        &--desktop {
          display: none;

          @include responsive-up(tablet) {
            display: flex;
          }
        }

        &--tablet {
          display: none;

          @include responsive-up(mobile) {
            display: flex;
          }

          @include responsive-up(tablet) {
            display: none;
          }
        }

        &--mobile {
          display: flex;

          @include responsive-up(mobile) {
            display: none;
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 55%;
        padding: 20px 20px 40px;
        position: absolute;
        top: 25%;
        left: 0;
        z-index: 2;

        @include responsive-up(mobile) {
          max-width: 500px;
          justify-content: center;
          align-content: center;
          height: 100%;
          top: 0;
          left: calc(50% - 250px);
        }

        @include responsive-up(tablet) {
          max-width: 800px;
          left: calc(50% - 400px);
        }

        @include responsive-up(desktop-large-extra) {
          max-width: 1200px;
          left: calc(50% - 600px);
        }

        h1 {
          font-size: 30px;
          color: $white;
          line-height: 32px;
          text-align: center;

          @include responsive-up(tablet) {
            font-size: 60px;
            line-height: 72px;
          }

          @include responsive-up(desktop-large-extra) {
            font-size: 80px;
            line-height: 92px;
          }
        }
      }

      &__buttons {
        padding: 0 10px;

        @include responsive-up(mobile) {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 75px;
        }

        a {
          @include responsive-up(mobile) {
            min-width: 125px;
            max-width: 125px;
            margin: 0 20px;
          }

          @include responsive-up(desktop-large-extra) {
            min-width: 225px;
            margin: 0 40px;
          }

          &:first-child {
            margin-bottom: 20px;

            @include responsive-up(mobile) {
              margin-bottom: 0;
            }
          }
        }
      }

      &__text-content {
        margin: 40px 20px;

        @include responsive-up(tablet) {
          max-width: 1080px;
          margin: 80px auto;
        }
      }

      &__text-seo {
        margin: 40px 20px;

        @include responsive-up(tablet) {
          max-width: 1080px;
          margin: 80px auto;
          columns: 2;
        }

        h2 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
          font-weight: 400;

          @include responsive-up(tablet) {
            margin: 0 20px 20px;
          }

          @include responsive-up(desktop-large-extra) {
            font-size: 24px;
            line-height: 28px;
          }
        }

        p {
          margin: 10px 0;
          font-size: 14px;
          line-height: 25px;
          color: $dark-grey;
          font-weight: 300;

          strong {
            font-weight: 400;
          }

          @include responsive-up(tablet) {
            margin: 10px 20px;
          }

          @include responsive-up(desktop-large-extra) {
            font-size: 16px;
            line-height: 28px;
          }

          @include responsive-down(tablet) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
