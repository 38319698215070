/* Pages: Critical: Product
--------------------------------------------------------------------------------------------------------------------- */
.page-product {
  main {
    @include responsive-up(tablet) {
      margin-top: 76px;
    }
  }

  .visible--desktop {
    display: none;

    @include responsive-up(tablet) {
      display: block;
    }
  }

  .product-content {
    position: relative;

    @include responsive-up(tablet) {
      display: flex;
      flex-wrap: wrap;
    }

    .accordion {
      border-top: none;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px 20px;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 8;

      @include responsive-up(tablet) {
        position: initial;
        top: inherit;
        left: inherit;
        padding: 0 0 25px;
        background-color: transparent;
      }

      i {
        font-size: 18px;
        line-height: 18px;
      }

      .arrow {
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;

        @include responsive-up(tablet) {
          display: none;
        }

        span {
          position: absolute;
          width: 10px;
          height: 2px;
          border-radius: 2px;
          background-color: $dark-grey;
          display: inline-block;
          transition: all 0.2s ease;

          &:first-child {
            transform: rotate(320deg);
            left: 0;
            top: 4px;
          }

          &:last-child {
            transform: rotate(225deg);
            top: 10px;
          }
        }
      }

      .wishlist-mobile {
        display: none;

        @include responsive-down(tablet) {
          display: inline-block;
        }
      }

      &.active {
        @include responsive-up(tablet) {
          z-index: -1;
        }
      }
    }

    &__breadcrumbs {
      .breadcrumb {
        display: flex;
        align-items: center;

        li {
          @include responsive-up(tablet) {
            font-size: 11px;
            line-height: 11px;
            margin-left: 7px;
            padding-left: 7px;
            color: $black;
            border-left: 1px solid $light-grey;
          }

          @include responsive-down(tablet) {
            &:nth-child(n+1) {
              display: none;
            }
          }

          &:last-child,
          &:first-child {
            display: none;

            @include responsive-up(tablet) {
              display: inline-block;
            }
          }

          &:first-child {
            display: inline-block;

            @include responsive-up(tablet) {
              border-left: 0;
              padding-left: 0;
              margin-left: 0;
            }
          }

          a,
          span {
            @include responsive-up(tablet) {
              font-size: 11px;
              color: #989898;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .breadcrumb-item {
        &__text {
          display: none;

          @include responsive-up(tablet) {
            display: inline-block;
          }
        }
      }
    }

    &__h1 {
      width: 80%;
      font-size: 16px !important;
      line-height: 20px !important;
      margin-bottom: 5px !important;
      font-weight: 300;

      @include responsive-up(tablet) {
        width: calc(100% - 100px);
        margin: 0;
      }

      @include responsive-up(desktop-large-extra) {
        font-size: 22px !important;
        line-height: 30px !important;
      }
    }

    &__gallery {
      background-color: $product-background-grey;
      position: relative;

      @include responsive-up(tablet) {
        width: calc(100% - 550px);
        margin: 25px 0 25px 50px;
        background-color: transparent;
      }

      .tns-nav {
        margin-top: -4px;
        display: flex;
        justify-content: center;
        padding: 10px 10px 20px;

        @include responsive-down(tablet) {
          margin-top: 0;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }

        button {
          width: 7px;
          height: 7px;
          margin: 0 4px;
          padding: 0;
          border-radius: 100%;
          border: none;
          background-color: grey;
          opacity: 0.5;

          &.tns-nav-active {
            background-color: $grey;
            opacity: 1;
          }
        }
      }

      img {
        width: 100%;
      }

      .gallery-columns {
        @include responsive-up(tablet) {
          display: flex;
          flex-wrap: wrap;
        }
      }

      figure {
        @include responsive-down(tablet) {
          background-color: $light-light-grey;
          padding: 50px;
        }
      }
    }

    figure {
      @include responsive-up(tablet) {
        width: calc(50% - 5px);
        margin-bottom: 10px;
        overflow: hidden;

        &:nth-child(2n+1) {
          margin-right: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__reference {
      color: $dark-grey;
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 20px;

      @include responsive-up(tablet) {
        margin: 0 0 20px;
        width: calc(100% - 100px);
      }

      strong {
        font-weight: 300;
      }
    }

    &__gallery-link {
      width: 100%;

      @include responsive-up(tablet) {
        height: 100%;
      }
    }

    &__picture {
      width: 100%;

      @include responsive-up(tablet) {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 100%;
      }
    }
  }

  .product-tabs {
    margin: 25px 0;

    &__container {
      &.container.container--medium-small {
        @include responsive-up(tablet) {
          width: calc(100% - 550px);
          max-width: 100%;
          margin-left: 50px !important;
          padding: 0;
        }

        @include responsive-up(tablet-extra-large) {
          padding: 0 100px;
        }

        @include responsive-up($desktop-large-extra) {
          width: calc(100% - 850px);
        }
      }
    }

    &__title {
      margin-bottom: 25px;
    }

    &__content {
      ul {
        padding-left: 24px;

        li {
          font-size: 12px;
          margin-bottom: 20px;
          color: $dark-grey;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: -24px;
            top: 8px;
            width: 14px;
            height: 1px;
            background-color: $dark-grey;
          }
        }
      }
    }
  }

  .product__discounts {
    position: inherit;
    top: inherit;
    left: inherit;

    @include responsive-up(tablet) {
      display: flex;
      flex-direction: column;
    }

    .product-flag {
      margin-top: 10px;
      padding: 3px 7px;

      @include responsive-up(tablet) {
        order: 1;
        margin-top: 0;
        margin-bottom: 5px;
      }
    }

    &__price {
      line-height: 22px;

      @include responsive-up(tablet) {
        order: 2;
      }

      @include responsive-down(tablet) {
        line-height: 28px;
      }
    }
  }

  .product-availability {
    .social-sharing {
      display: none !important;
    }

    p {
      color: $dark-grey;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .input-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin: 15px 0 0;

      .form-control {
        width: calc(100% - 120px);
        padding: 11px;
        border: 1px solid #989898;
        border-right: 0;
        font-size: 12px;
        color: #989898;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      button {
        width: 120px;
        font-size: 12px;
        letter-spacing: normal;
        text-transform: inherit;
        padding: 6px 18px;
        border: 1px solid #000;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      & > span {
        display: flex;
        width: 100%;

        .alert {
          width: 100%;
          text-align: center;
        }
      }
    }

    .psgdpr_consent_message {
      display: flex;
      align-items: flex-start;
      margin-top: 15px;

      input {
        margin-right: 5px;
      }

      span {
        font-size: 11px;
        line-height: 11px;
        color: $grey;
      }
    }
  }

  .product-information {
    position: relative;
    padding-top: 20px;

    @include responsive-up(tablet) {
      position: absolute;
      right: 0;
      width: 500px;
      margin-top: 25px;
      background-color: transparent;
      order: 3;
      z-index: 8;
      padding-top: 0;
      height: 100%;
    }

    @include responsive-up($desktop-large-extra) {
      width: 800px;
    }

    .cnt-sticky {
      @include responsive-up(tablet) {
        position: sticky;
        top: 105px;
        height: auto;
      }
    }

    &__brand {
      position: absolute;
      right: 20px;
      top: 20px;

      @include responsive-up(tablet) {
        right: 0;
        top: 38px;
      }

      img {
        width: 75px;
        height: auto;
        filter: grayscale(1);

        @include transition(all 0.325s ease-in-out);
      }

      &:hover {
        @include responsive-up(tablet) {
          img {
            filter: inherit;

            @include transition(all 0.325s ease-in-out);
          }
        }
      }
    }

    &__colours {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 40px 0;

      @include responsive-up(tablet) {
        margin: 25px 0;
      }
    }

    &__title-colours {
      width: 100%;

      @include responsive-up(tablet) {
        font-size: 12px;
      }
    }

    &__color {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50px;
      margin-right: 15px;
      margin-top: 15px;

      @include transition(all 0.325s ease-in-out);

      @include responsive-up(tablet) {
        margin-top: 7px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
        overflow: hidden;
      }

      span {
        font-size: 11px;
        margin-top: 4px;
      }

      &--selected {
        img {
          border: 1px solid $black;
        }
      }

      &:hover {
        @include responsive-up(tablet) {
          opacity: 1;

          @include transition(all 0.325s ease-in-out);

          img {
            border: 1px solid $black;
          }
        }
      }

      &.product-information__color--selected {
        opacity: 1;

        @include transition(all 0.325s ease-in-out);
      }
    }

    .product-variants {
      margin: 25px 0;

      @include responsive-up(tablet) {
        position: relative;
      }

      &__title {
        span {
          font-size: 17px;
        }

        label {
          @include responsive-up(tablet) {
            font-size: 12px;
          }
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        margin: 15px 0 0;

        @include responsive-up(tablet) {
          margin: 7px 0 0;
          width: calc(100% + 10px);
        }

        .custom-control {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          position: relative;
          margin-right: 15px;
          margin-bottom: 15px;

          @include responsive-up(tablet) {
            margin-right: 10px;
            margin-bottom: 10px;
          }

          @include responsive-up(desktop-large-extra) {
            width: 50px;
            height: 50px;
          }

          input {
            border: 1px solid transparent;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            z-index: 8;
            position: absolute;
            left: 0;
            top: 0;
            padding: 10px;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &:hover {
              @include responsive-up(tablet) {
                border: 1px solid $black;
              }
            }
          }

          label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            max-height: 100%;
            font-size: 13px;
            padding: 10px;
            background-color: $light-light-grey;
            border-radius: 4px;
            top: inherit;
            z-index: 7;
            cursor: pointer;
          }

          &--selected {
            color: $white;

            label {
              background: $black;
            }
          }

          &--nostock {
            cursor: not-allowed;

            .custom-control-label {
              cursor: not-allowed;
            }

            label {
              color: #c4c4c4;
            }

            input {
              &:hover {
                @include responsive-up(tablet) {
                  border-color: $light-light-grey;
                }
              }
            }

            &:hover {
              @include responsive-up(tablet) {
                cursor: not-allowed;
              }
            }
          }
        }

        select {
          min-width: 150px;
          padding: 8px;
        }
      }

      & > .btn {
        @include responsive-up(tablet) {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 11px;
        }

        @include responsive-up(desktop-large-extra) {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }

    .productstores {
      font-size: 12px;
    }

    .text-shipping {
      display: flex;
      align-items: center;

      @include responsive-up(tablet) {
        order: 2;
      }

      @include responsive-up(desktop-large-extra) {
        margin-top: 20px;
      }

      i {
        font-size: 13px;
        margin-right: 5px;
      }

      p {
        font-size: 13px;
        line-height: 30px;
      }
    }

    .cart-button-container {
      margin: 25px 0;

      .add {
        .btn-add-to-cart {
          &--disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }

      @include responsive-up(tablet) {
        display: flex;
        flex-direction: column;

        .product-add-to-cart {
          order: 1;
        }

        .add {
          display: flex;
          align-items: center;

          a {
            font-size: 18px;
            margin-left: 25px;
          }
        }
      }

      i.js-productwishlist-added {
        background: $red-primary;
        color: $white;
        padding: 4px 3px 3px;
        border-radius: 50%;
      }

      .wishlist-desktop {
        display: none;

        @include responsive-up(tablet) {
          display: inline-block;
        }
      }
    }

    .btn-fixed {
      display: none;
      position: fixed;
      width: 100%;
      padding: 20px;
      left: 0;
      bottom: 0;
      background: $white;
      z-index: 8;
      box-shadow: -2px 0 10px rgba(0, 0, 0, 0.05);

      @include transition(all 0.2s ease);
    }

    &.container {
      @include responsive-up(tablet) {
        padding-left: 35px;
      }
    }

    &.active {
      @include responsive-up(tablet) {
        z-index: -1;
      }
    }
  }

  .text-delivery {
    display: flex;
    align-items: center;
    margin: 25px 0 50px;

    i {
      font-size: 26px;
      margin-right: 8px;
    }

    p {
      font-size: 12px;
      color: $dark-grey;

      a {
        font-size: 12px;
        margin-left: 3px;
      }
    }

    .text-delivery {
      margin: 0;
    }
  }

  .pswp--svg .pswp__button--arrow--left::before {
    font-family: $icomoon;
    color: $white;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;

    @include responsive-down(tablet) {
      color: $black;
    }
  }

  .pswp--svg .pswp__button--arrow--right::before {
    font-family: $icomoon;
    color: $white;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;

    @include responsive-down(tablet) {
      color: $black;
    }
  }

  .pswp__ui--idle .pswp__button--arrow--left,
  .pswp__ui--idle .pswp__button--arrow--right {
    opacity: 1 !important;
  }

  .custom-modal {
    overflow-y: auto;
  }
}
