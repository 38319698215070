/* Components: Category filters
--------------------------------------------------------------------------------------------------------------------- */
.filters {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px 0;
  background: $white;
  overflow-x: hidden;
  z-index: -1;
  visibility: hidden;
  opacity: 0;

  @include transition(all 0.5s ease-in-out);

  @include responsive-down(tablet) {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 20px;

    @include transition(all 0.25s ease-in-out);
  }

  * {
    opacity: 0;

    @include transition(all 0.25s ease-in-out);

    @include responsive-down(tablet) {
      @include transition(all 0.125s ease-in-out);
    }
  }

  .close-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    position: fixed;
    top: 20px;
    right: 20px;
    opacity: 0;
    cursor: pointer;
    border-radius: 100px;
    z-index: 100;
    background-color: $white;
    padding: 5px;

    @include transition(all 0.25s ease-in-out);

    @include responsive-down(tablet) {
      @include transition(all 0.125s ease-in-out);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 80%;
      top: calc(10% + 7px);
      left: 10%;
      right: 0;
      height: 2px;
      background: $black;
      border-radius: 4px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

    &:hover {
      @include responsive-up(tablet) {
        background-color: $black;

        &::before,
        &::after {
          background: $white;
        }
      }
    }
  }

  &--opened {
    width: 400px;
    visibility: visible;
    opacity: 1;
    padding: 20px;
    z-index: 10;

    @include transition(all 0.5s ease-in-out);

    @include responsive-down(tablet) {
      width: 100%;

      @include transition(all 0.25s ease-in-out);
    }

    * {
      opacity: 1;

      @include transition(all 0.6s ease-in-out);

      @include responsive-down(tablet) {
        @include transition(all 0.3s ease-in-out);
      }
    }

    .gridview__item {
      opacity: 0.25;

      &--active {
        opacity: 1;
      }
    }

    .close-icon {
      opacity: 1;

      @include transition(all 0.25s ease-in-out);
    }
  }

  .filter-by {
    display: block;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__fixed-container {
    width: 100%;
    background: $white;
    padding: 10px 0 0;
    z-index: 10;
  }
}

.background-shadow {
  width: calc(100% - 400px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;

  @include transition(opacity 0.75s ease-in-out);
  @include transition(z-index 0.5s ease-in-out);

  @include responsive-down(tablet) {
    display: none;
  }
}

.shadow--opened {
  .background-shadow {
    z-index: 9;
    visibility: visible;
    opacity: 1;
    transition-delay: 0.5;

    @include transition(opacity 2s ease-in-out);
  }
}

.filters-btn {
  display: flex;
  align-items: center;
  font-size: 15px;

  @include responsive-up(tablet) {
    cursor: pointer;
    font-size: 12px;
    margin-right: 30px;
  }

  & > span {
    line-height: 12px;
    margin-left: 8px;
  }

  .filters-icon {
    svg {
      * {
        transition: 0.15s cubic-bezier(0.35, 0.35, 0, 1);
      }
    }

    circle {
      will-change: transform;
      stroke-width: 2px;
    }

    rect {
      transform-origin: 50% 50%;
    }
  }

  &:hover {
    .fltr-crcl1 {
      transform: translateX(15px);
    }

    .fltr-crcl2 {
      transform: translateX(-15px);
    }
  }
}

.active_filters {
  display: flex;
  flex-direction: column;
  width: calc(100% + 40px);
  margin: 0 -20px;
  padding: 0 20px 15px;
  border-bottom: 1px solid #e0e0e0;

  &__title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;

    &-aplicated {
      font-size: 14px;
    }
  }

  &__list {
    width: 100%;
    margin: 15px 0 0;
  }

  &__list-item {
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 6px;
    border: 1px solid $light-grey;
    border-radius: 3px;
    font-size: 11px;
    color: $dark-grey;
    position: relative;
    cursor: pointer;

    @include transition(all 0.25s ease-in-out);

    &::after {
      content: "x";
      font-size: 15px;
      font-weight: 100;
      margin-left: 10px;
      line-height: 12px;
      margin-top: 0;
      display: inline-block;
    }

    &:hover {
      @include responsive-up(tablet-large) {
        color: $black;
        border-color: $black;

        @include transition(all 0.25s ease-in-out);
      }
    }
  }
}

.search_filters {
  .custom-clear-filters {
    position: fixed;
    top: 55px;
    right: 20px;
    line-height: 12px;

    button {
      border: none;
      background-color: transparent;
      padding: 0;
      color: $dark-grey;
      text-decoration: underline;
      font-size: 12px;
      line-height: 12px;

      @include transition(all 0.25s ease-in-out);

      &:hover {
        @include responsive-up(tablet-large) {
          color: $black;
          text-decoration: none;
          cursor: pointer;

          @include transition(all 0.25s ease-in-out);
        }
      }
    }
  }
}

.facet__block {
  margin-bottom: 40px;
  width: 95%;

  .facet__header {
    margin-bottom: 10px;
  }

  .collpase--facet {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0 0;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include responsive-down(mobile) {
      flex-wrap: nowrap;
      width: calc(100% + 60px);
      margin: 15px -20px 0;
    }

    .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      position: relative;
      margin-right: 11px;
      margin-bottom: 11px;

      @include responsive-down(mobile) {
        &:first-child {
          margin-left: 20px;
        }

        &:last-child {
          margin-right: 20px;
        }
      }

      input[type="checkbox"],
      input[type="radio"] {
        border: 1px solid transparent;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 8;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:hover {
          @include responsive-up(tablet) {
            border: 1px solid $black;
          }
        }
      }

      .custom-control-label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 35px;
        height: 35px;
        font-size: 14px;
        padding: 10px;
        background-color: #f6f6f6;
        border-radius: 4px;
        z-index: 7;
        cursor: pointer;
      }

      // #facet_input_61119 {
      //   &_0 {
      //     & + label {
      //       background-color: #fcf75e;
      //     }
      //   }

      //   &_1 {
      //     & + label {
      //       background-color: #4040ff;
      //     }
      //   }

      //   &_2 {
      //     & + label {
      //       background-color: #ece0d7;
      //     }
      //   }

      //   &_3 {
      //     & + label {
      //       background-color: #fff;
      //     }
      //   }

      //   &_4 {
      //     & + label {
      //       background-color: #cd7f32;
      //     }
      //   }

      //   &_5 {
      //     & + label {
      //       background-color: #800040;
      //     }
      //   }

      //   &_6 {
      //     & + label {
      //       background-color: #c19a6b;
      //     }
      //   }

      //   &_7 {
      //     & + label {
      //       background-color: #e0d8b0;
      //     }
      //   }

      //   &_8 {
      //     & + label {
      //       background-color: #804000;
      //     }
      //   }

      //   &_9 {
      //     & + label {
      //       background-color: #f6f6f6;
      //     }
      //   }

      //   &_10 {
      //     & + label {
      //       background-color: #e7d3af;
      //     }
      //   }

      //   &_11 {
      //     & + label {
      //       background-color: #3f2817;
      //     }
      //   }

      //   &_12 {
      //     & + label {
      //       background-color: #8d4925;
      //     }
      //   }

      //   &_13 {
      //     & + label {
      //       background-color: #000;
      //     }
      //   }

      //   &_14 {
      //     & + label {
      //       background-color: #ffd700;
      //     }
      //   }

      //   &_15 {
      //     & + label {
      //       background-color: #8b8c7a;
      //     }
      //   }

      //   &_16 {
      //     & + label {
      //       background-color: #555;
      //     }
      //   }

      //   &_17 {
      //     & + label {
      //       background-color: #e62e1b;
      //     }
      //   }

      //   &_18 {
      //     & + label {
      //       background-color: #937a76;
      //     }
      //   }

      //   &_19 {
      //     & + label {
      //       background-color: #c93c20;
      //     }
      //   }
      // }

      // #facet_input_81554 {
      //   &-0 {
      //     & + label {
      //       background-color: #4040ff;
      //     }
      //   }

      //   &-1 {
      //     & + label {
      //       background-color: #d7d7a8;
      //     }
      //   }

      //   &-2 {
      //     & + label {
      //       background-color: #fff;
      //     }
      //   }

      //   &-3 {
      //     & + label {
      //       background-color: #bf8970;
      //     }
      //   }

      //   &-4 {
      //     & + label {
      //       background-color: #800040;
      //     }
      //   }

      //   &-5 {
      //     & + label {
      //       background-color: #c19a6b;
      //     }
      //   }

      //   &-6 {
      //     & + label {
      //       background-color: #e0d8b0;
      //     }
      //   }

      //   &-7 {
      //     & + label {
      //       background-color: #ff7f50;
      //     }
      //   }

      //   &-8 {
      //     & + label {
      //       background-color: #804000;
      //     }
      //   }

      //   &-9 {
      //     & + label {
      //       background-color: #e30052;
      //     }
      //   }

      //   &-10 {
      //     & + label {
      //       background-color: #f6f6f6;
      //     }
      //   }

      //   &-11 {
      //     & + label {
      //       background-color: #e7d3af;
      //     }
      //   }

      //   &-12 {
      //     & + label {
      //       background-color: #8d4925;
      //     }
      //   }

      //   &-13 {
      //     & + label {
      //       background-color: #e9bd15;
      //     }
      //   }

      //   &-14 {
      //     & + label {
      //       background:
      //         linear-gradient(217deg, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0) 70.71%),
      //         linear-gradient(127deg, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0) 70.71%),
      //         linear-gradient(336deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);
      //     }
      //   }

      //   &-15 {
      //     & + label {
      //       background-color: #ff8000;
      //     }
      //   }

      //   &-16 {
      //     & + label {
      //       background-color: #000;
      //     }
      //   }

      //   &-17 {
      //     & + label {
      //       background-color: #ebc8b2;
      //     }
      //   }

      //   &-18 {
      //     & + label {
      //       background-color: #ffd700;
      //     }
      //   }

      //   &-19 {
      //     & + label {
      //       background-color: #8b8c7a;
      //     }
      //   }

      //   &-20 {
      //     & + label {
      //       background-color: #e3e4e5;
      //     }
      //   }

      //   &-21 {
      //     & + label {
      //       background-color: #555;
      //     }
      //   }

      //   &-22 {
      //     & + label {
      //       background-color: #f00;
      //     }
      //   }

      //   &-23 {
      //     & + label {
      //       background-color: #d3c3b0;
      //     }
      //   }

      //   &-24 {
      //     & + label {
      //       background-color: #c37349;
      //     }
      //   }

      //   &-25 {
      //     & + label {
      //       background-color: #5dc1b9;
      //     }
      //   }

      //   &-26 {
      //     & + label {
      //       background-color: #008000;
      //     }
      //   }
      // }

      // #facet_input_87038 {
      //   &_0 {
      //     & + label {
      //       background-color: #fcf75e;
      //     }
      //   }

      //   &_1 {
      //     & + label {
      //       background-color: #d7d7a8;
      //     }
      //   }

      //   &_2 {
      //     & + label {
      //       background-color: #fff;
      //     }
      //   }

      //   &_3 {
      //     & + label {
      //       background-color: #cd7f32;
      //     }
      //   }

      //   &_4 {
      //     & + label {
      //       background-color: red;
      //     }
      //   }
      // }

      // #facet_input_81717 {
      //   &_0 {
      //     & + label {
      //       background-color: #d7d7a8;
      //     }
      //   }

      //   &_1 {
      //     & + label {
      //       background-color: #800040;
      //     }
      //   }

      //   &_2 {
      //     & + label {
      //       background-color: #804000;
      //     }
      //   }

      //   &_3 {
      //     & + label {
      //       background-color: #8d4925;
      //     }
      //   }

      //   &_4 {
      //     & + label {
      //       background-color: #000;
      //     }
      //   }

      //   &_5 {
      //     & + label {
      //       background-color: #937a76;
      //     }
      //   }
      // }

      // #facet_input_68999 {
      //   &_0 {
      //     & + label {
      //       background-color: #4040ff;
      //     }
      //   }

      //   &_1 {
      //     & + label {
      //       background-color: #d7d7a8;
      //     }
      //   }

      //   &_2 {
      //     & + label {
      //       background-color: #800040;
      //     }
      //   }

      //   &_3 {
      //     & + label {
      //       background-color: #c19a6b;
      //     }
      //   }

      //   &_5 {
      //     & + label {
      //       background-color: #000;
      //     }
      //   }

      //   &_6 {
      //     & + label {
      //       background-color: #e5d2c4;
      //     }
      //   }

      //   &_7 {
      //     & + label {
      //       background-color: #8b8c7a;
      //     }
      //   }

      //   &_8 {
      //     & + label {
      //       background-color: #d3c3b0;
      //     }
      //   }
      // }

      // #facet_input_64973 {
      //   &_0 {
      //     & + label {
      //       background-color: #4040ff;
      //     }
      //   }

      //   &_1 {
      //     & + label {
      //       background-color: #d7d7a8;
      //     }
      //   }

      //   &_2 {
      //     & + label {
      //       background-color: #c19a6b;
      //     }
      //   }

      //   &_3 {
      //     & + label {
      //       background-color: #804000;
      //     }
      //   }

      //   &_4 {
      //     & + label {
      //       background-color: #000;
      //     }
      //   }

      //   &_5 {
      //     & + label {
      //       background-color: #e5d2c4;
      //     }
      //   }

      //   &_6 {
      //     & + label {
      //       background-color: #f00;
      //     }
      //   }

      //   &_7 {
      //     & + label {
      //       background-color: #d3c3b0;
      //     }
      //   }
      // }

      &--active {
        .custom-control-label {
          background-color: $black;
          color: $white;
        }
      }
    }

    select {
      width: 100%;
      padding: 5px;
    }
  }

  .faceted-slider {
    padding-top: 10px;

    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default {
      background: $black;
      font-weight: 400;
      border-radius: 100%;
      border: 4px solid $white;
      width: 25px;
      height: 25px;
    }

    .ui-slider-handle {
      top: -0.8em;
    }

    .ui-widget-content {
      height: 3px;
      border: none;
      background: #c4c4c4;

      .ui-slider-range {
        background: $black;
      }

      .ui-state-hover {
        cursor: pointer;
      }

      .ui-state-active {
        outline: none;
        transform: scale(1.2);

        @include transition(all 0.25s ease-in-out);
      }

      .ui-state-focus {
        outline: none;
      }
    }
  }

  .price-range {
    margin-top: 20px;
    font-size: 15px;
  }

  &--colour {
    .collpase--facet {
      padding-left: 10px;

      .custom-control {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        height: auto;
        margin-right: 18px;
        min-height: 40px;

        input[type="radio"] {
          position: initial;
          background-color: #f6f6f6;
          height: 28px;
          width: 28px;
        }

        input[type="checkbox"] {
          height: 25px;
        }

        .custom-control-label {
          height: auto;
          font-size: 10px;
          padding: 3px;
        }

        &.facet__block-color219 {
          input[type="radio"] {
            background-color: #73bbdb;
          }
        }

        &.facet__block-color221 {
          input[type="radio"] {
            background-color: #a06a14;
          }
        }

        &.facet__block-color893 {
          input[type="radio"] {
            background-color: #5b935e;
          }
        }

        &.facet__block-color244 {
          input[type="radio"] {
            background-color: #171717;
          }
        }
      }
    }

    .custom-control-label {
      width: 25px !important;
      height: 25px !important;
      max-width: 25px !important;
      min-width: 25px !important;
      padding: 0;

      .color__label {
        position: absolute;
        bottom: -5px;
      }
    }
  }

  &--size {
    .collpase--facet {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      margin: 15px 0;
      overflow-x: inherit;
      overflow-y: inherit;
      white-space: inherit;

      .custom-control {
        .custom-control-label {
          font-size: 12px;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.subcategory-filters {
  padding-bottom: 80px;
  margin-bottom: 80px;

  &__content {
    .order-by {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 0 0;

      &__label {
        display: block;
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
  }

  &__button {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    padding: 20px;
    background: $white;
    z-index: 9;

    @include responsive-down(tablet-large) {
      width: 100%;
    }

    button {
      background-color: $black;
      color: $white;
      border: none;
      width: 100%;
      padding: 12px;
      border-radius: 3px;

      @include transition(all 0.325s ease-in-out);

      &:hover {
        @include responsive-up(tablet-large) {
          background-color: $dark-grey;
          cursor: pointer;

          @include transition(all 0.325s ease-in-out);
        }
      }
    }
  }
}

.hide {
  display: none;
}
